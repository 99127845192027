<template>
    <div class="honorBox">
        <!-- 头部大图 -->
        <div class="hbBg" v-if="getAdvData(33).children">
            <a :href="getAdvData(33).children[0].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(33).children[0].image" />
            </a>
        </div>
        <div class="hbBox">
            <!-- 标题 -->
            <div class="hbTitle">荣誉资质</div>
            <!-- 导航 -->
            <div class="hbTabs">
                <ul>
                    <template v-for="el in navList">
                        <li v-if="el.status" class="htLi" :key="el.id">
                            <div :class="{ htActive: isActive === el.id }" @click="isActive = el.id">{{ el.title }}</div>
                        </li>
                    </template>
                </ul>
            </div>
            <!-- 伪装的图片列表 -->
            <div class="hbList">
                <vue-waterfall :list="HonData" :columnCount="5">
                    <template slot-scope="{ e }">
                        <div class="anli" @click="open(e.index)">
                            <img v-lazy="e.image" class="little_image" />
                        </div>
                    </template>
                </vue-waterfall>
            </div>
            <!-- 真实的预览图片列表 -->
            <div id="viewer" style="display: none">
                <div v-for="el in HonData" :key="el.id">
                    <img :src="el.image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import VueWaterfall from "@/util/vue-waterfall.vue";

export default {
    name: "Honor",
    inject: ["getAdvData"],
    components: { VueWaterfall },
    data() {
        return {
            // 激活导航
            isActive: 1,
            navList: [],
            i: 0,
        };
    },
    created() {
        axios.get("/api/honor/onetwo").then(({ data }) => {
            this.navList = data;
            this.isActive = data[0].id;
            this.$nextTick(function () {
                this.viewer = new Viewer(document.getElementById("viewer"), {
                    title: false,
                    show: (a, b, c) => {
                        let li = document.querySelector(".viewer-toolbar>ul .viewer-custom");
                        if (this.HonData[this.i].url) {
                            li.style.display = "block";
                        } else {
                            li.style.display = "none";
                        }
                    },
                    ready: () => {
                        let li = document.createElement("li");
                        let toolbar = document.querySelector(".viewer-toolbar>ul");
                        li.innerHTML = `<div style="width: 24px; height: 24px; position: relative; overflow: hidden">
                            <img src="https://iv.vu818.com/img/vu1212.png" style="position: absolute; top: -507px; left: -273px;width: 1080px;" />
                        </div>`;
                        li.className = "viewer-custom";
                        this.toolbar = toolbar;
                        toolbar.append(li);
                        li.onclick = () => {
                            window.open("http://" + this.HonData[this.i].url);
                        };
                    },
                });
            });
        });
    },
    beforeDestroy() {
        this.viewer.destroy();
    },
    methods: {
        open(index) {
            this.viewer.view(index);
            this.i = index;
        },
    },
    computed: {
        HonData() {
            if (!this.navList.length) return [];
            // 返回荣誉图片数据
            const arr = this.navList.find(e => e.id == this.isActive).children;
            arr.forEach((e, index) => {
                e.index = index;
            });
            return arr;
        },
    },
    watch: {
        isActive() {
            this.$nextTick(function () {
                // 重新渲染viewer
                try {
                    this.viewer.update();
                } catch (error) {}
            });
        },
    },
};
</script>

<style lang="less" scoped>
.honorBox {
    width: 100%;
    min-height: 1200px;
    margin-top: 87px;
    margin-bottom: 20px;
    // 头部大图
    .hbBg {
        width: 100%;
        // height: 400px;
        & > a img {
            width: 100%;
        }
    }
    .hbBox {
        width: 95%;
        // max-width: 1280px;
        // min-height: 700px;
        margin: 0 auto;
        // 标题
        .hbTitle {
            width: 30%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
            font-size: 36px;
        }
        // 导航
        .hbTabs {
            width: 100%;
            min-height: 60px;
            margin: 0 auto;
            margin-top: 20px;
            position: sticky;
            top: 87px;
            z-index: 666;
            // background-color: #fff;
            // overflow: hidden;
            ul {
                width: 100%;
                min-height: 40px;
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: left;
                align-items: center;
                .htLi {
                    width: calc(100% / 8);
                    text-align: center;
                    margin-bottom: 10px;
                    & > div {
                        width: 90%;
                        margin: 0 auto;
                        line-height: 40px;
                        height: 40px;
                        border-radius: 20px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        cursor: pointer;
                        background-color: #fff;
                    }
                }

                .htActive {
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                }
            }
        }
        // 图片列表
    }
    .showBi {
        display: block;
    }
}
.anli {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    transition: 0.5s;
    overflow: hidden;
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px 1px;
    position: relative;
    cursor: pointer;
    .little_image {
        width: 100%;
        // margin-bottom: -2%;
        object-fit: cover;
        position: relative;
    }
    &:hover {
        transform: scale(1.1);
        .little_image {
            position: relative;
        }
    }
}
</style>
